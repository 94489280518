const firebaseConfig = {
    apiKey: "AIzaSyAi8iSiUoIHgDrU9ct4nowfW5WwXsirb5I",
    authDomain: "surajmalmatrimoni.firebaseapp.com",
    projectId: "surajmalmatrimoni",
    storageBucket: "surajmalmatrimoni.appspot.com",
    messagingSenderId: "540787653314",
    appId: "1:540787653314:web:07f1caa6a492e2807f995b",
    measurementId: "G-J80YHDCTBE",
    vapidKey: "BI6xHDRyreO7Sj-XBnVIQTdq_bZMtIWBKj6mY2DSkOjWQE1rWGBelF47Di-DMPC7GnLLprbc3W1UPHpQwx0cZFg"

};

export default firebaseConfig;